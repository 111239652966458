/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { AppTooltipModule } from '../../_directives/tooltip/tooltip.module';
import { AwsPipesModule } from '../../_pipes/aws-pipes/aws-pipes.module';
import { ColorPipeModule } from '../../_pipes/color-pipe/color-pipe.module';
import { EquipmentPipeModule } from '../../_pipes/equipment/equipment.pipe.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { ChartModule } from '../chart/chart.module';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { AppCountUpModule } from '../count-up/count-up.module';
import { DatagridModule } from '../datagrid/datagrid.module';
import { CabinCallDatagridModule } from '../datagrids/cabin-call-datagrid/cabin-call-datagrid.module';
import { DoorStateDatagridModule } from '../datagrids/door-state-datagrid/door-state-datagrid.module';
import { FailureDatagridModule } from '../datagrids/failure-datagrid/failure-datagrid.module';
import { MouvementDataDatagridModule } from '../datagrids/mouvement-data-datagrid/mouvement-data-datagrid.module';
import { DisponibilityRateLabelModule } from '../disponibility-rate-label/disponibility-rate-label.module';
import { DocxViewerModule } from '../docx-viewer/docx-viewer.module';
import { ElevatorModule } from '../elevator/elevator.module';
import { EntityDocumentViewerComponent } from '../entity-document-viewer/entity-document-viewer.component';
import { EquipmentDisponibilityInfoModule } from '../equipment-disponibility-info/equipment-disponibility-info.module';
import { EquipmentFailureInfoModule } from '../equipment-failure-info/equipment-failure-info.module';
import { EquipmentModule } from '../equipment/equipment.module';
import { FailureCountLabelModule } from '../failure-count-label/failure-count-label.module';
import { GalleryModule } from '../gallery/gallery.module';
import { AppLoaderModule } from '../loader/loader.module';
import { AppMapModule } from '../map/map.module';
import { PicturGridComponent } from '../picture-grid/picture-grid.component';
import { StarRateModule } from '../star-rate/star-rate.module';
import { XlsxViewerModule } from '../xlsx-viewer/xlsx-viewer.module';
import { ElevatorInfoDialog } from './elevator-info.dialog';
import { ElevatorInfoEntryDialog } from './elevator-info.entry.dialog';
import { InitializationControlDialog } from './initialization-control/initialization-control.dialog';
import { NetworkControlDialog } from './network-control/network-control.dialog';
import { SesioNodeComponentControlDialog } from './sesio-node-component-control/sesio-node-component-control.dialog';
import { HistoriqueChartComponent } from './signature-control/historique-chart/historique-chart.component';
import { IndicatorsPipe } from './signature-control/indicators.pipe';
import { SignatureControlDialog } from './signature-control/signature-control.dialog';

@NgModule({
  declarations: [
    ElevatorInfoDialog,
    ElevatorInfoEntryDialog,
    SignatureControlDialog,
    SesioNodeComponentControlDialog,
    NetworkControlDialog,
    InitializationControlDialog,
    IndicatorsPipe,
    HistoriqueChartComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    StarRateModule,
    ColorPipeModule,
    NgScrollbarModule,
    ChartModule,
    AppCountUpModule,
    EquipmentModule,
    ElevatorModule,
    MatRippleModule,
    ExtentedPipesModule,
    AppMapModule,
    MatDialogModule,
    AppFlexLayoutModule,
    MatTableModule,
    MatTabsModule,
    MatGridListModule,
    MatTooltipModule,
    MatExpansionModule,
    AwsPipesModule,
    PicturGridComponent,
    NgxExtendedPdfViewerModule,
    NgxDocViewerModule,
    MatProgressSpinnerModule,
    DocxViewerModule,
    XlsxViewerModule,
    GalleryModule,
    EntityDocumentViewerComponent,
    AppLoaderModule,
    MatCardModule,
    MatMenuModule,
    ConfirmDialogModule,
    DatagridModule,
    AppTooltipModule,
    EquipmentFailureInfoModule,
    EquipmentDisponibilityInfoModule,
    MatListModule,
    DisponibilityRateLabelModule,
    FailureCountLabelModule,
    MatDividerModule,
    MatSelectModule,
    IntersectionObserverModule,
    MatPaginatorModule,
    MatSortModule,
    CabinCallDatagridModule,
    FailureDatagridModule,
    DoorStateDatagridModule,
    MouvementDataDatagridModule,
    EquipmentPipeModule,
    MatSnackBarModule,
  ],
  exports: [ElevatorInfoDialog, ElevatorInfoEntryDialog],
})
export class ElevatorInfoModule {}
