/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatagridModule } from '../../datagrid/datagrid.module';
import { DoorStateDatagridComponent } from './door-state-datagrid.component';
import { NgxMatDatatableModule } from '@eherve/angular-material-datatable';

@NgModule({
  declarations: [DoorStateDatagridComponent],
  imports: [CommonModule, DatagridModule, NgxMatDatatableModule],
  exports: [DoorStateDatagridComponent],
})
export class DoorStateDatagridModule {}
